import { Injectable } from "@angular/core";
import { AuthService } from "./Auth.service";
import { Location } from '@angular/common';
import { HistoryEntry, RouterHistoryService } from "./RouterHistory.service";
import { share, take } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { ConfirmationService } from "primeng/api";
import { NavigationExtras, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import { PubSubService } from "./PubSub.service";
import { Joove } from "../../@framework/core/Joove";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})

export class ClientCommandsService {

  controlCommands$ = new Subject<{ command: ClientCommands; params: any[] }>();

  confirmOptions$ = new Subject<{ className: string }>();

  constructor(private authService: AuthService,
    private location: Location,
    private routerHistoryService: RouterHistoryService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private httpClient: HttpClient,
    private pubSubService: PubSubService
  ) {
    this.baseUrl = environment.appUrl;
  }

  private baseUrl: string;

  public lastHtmlMouseEvent: MouseEvent;

  public execute(commands: ClientCommand[]) {
    for (let command of commands) {
      this.executeCommand(ClientCommands[command.Command], command.Params);
    }
  }

  public executeCommand(command: ClientCommands, params: any[] = null) {
    switch (command) {
      case ClientCommands.REDIRECT:
        window.open(params[0], params[1] ?? '_self');
        break;
      case ClientCommands.SET_AUTH_TOKEN:
        this.setAuthToken(params[0]);
        break;
      case ClientCommands.UNSET_AUTH_TOKEN:
        this.unsetAuthToken();
        break;
      case ClientCommands.PUSH_TO_NAVIGATION_HISTORY:
        //TODO: implement
        break;
      case ClientCommands.CLOSE_FORM:
        this.closeForm();
        break;
      case ClientCommands.SHOW_MESSAGE:
        let type = params.length > 1 ? params[1] : "Success";
        let redirectUrl = params.length > 2 ? params[2] : null;
        let messageParams = {
          Message: params[0],
          Title: ' ',
          Type: type,
          RedirectUrl: redirectUrl
        };
        this.handleShowMessage(messageParams);
        break;
      case ClientCommands.DOWNLOAD:
        // todo: use a more reliable way to get the 'Controller' part of the url instead of the current route
        //const controller = this.router.url.split('/').filter(t => t !== '')[0];
        this.downloadFile(`${this.baseUrl}${params[1]}/DownloadFile?id=${params[0]}`);
        break;
      default:
        this.controlCommands$.next({ command, params });

    }
  }

  public onConfirmOptions(): Observable<{ className: string }> {
    return this.confirmOptions$.pipe(share());
  }

  public onControlCommand(): Observable<{ command: ClientCommands; params: any[] }> {
    return this.controlCommands$.pipe(share());
  }

  public clientCommanderHandler(component: any, command: ClientCommands, params: any[] = null) {

    const controlName = params[0] + 'Control';

    if (command === ClientCommands.EXECUTE_JS) {
      const controllerName = params[1];
      const componentName = component?.constructor?.name;

      if (!componentName?.startsWith(controllerName)) {
        return;
      }
    } else {

      if (!(controlName in component)) {
        return;
      }
    }

    try {
      switch (command) {
        case ClientCommands.SHOW_MODAL:
          component[controlName]?.showDialog();
          break;
        case ClientCommands.HIDE_MODAL:
          component[controlName]?.hideDialog();
          break;
        case ClientCommands.SHOW_CONTEXT_MENU:
          component[controlName]?.show(this.lastHtmlMouseEvent);
          break;
        case ClientCommands.HIDE_CONTEXT_MENU:
          component[controlName]?.hide();
          break;
        case ClientCommands.DROPDOWN_REFRESH:
          component[controlName]?.refresh();
          break;
        case ClientCommands.CALENDAR_REFRESH:
          component[controlName]?.refresh();
          break;
        case ClientCommands.CHART_REFRESH:
          component[controlName]?.refresh();
          break;
        case ClientCommands.MAP_REFRESH:
          component[controlName]?.refresh();
          break;
        case ClientCommands.LIST_REFRESH:
        case ClientCommands.DATALIST_REFRESH:
        case ClientCommands.GRID_REFRESH:
        case ClientCommands.PIVOTTABLE_REFRESH:
          component[controlName]?.refresh();
          break;
        case ClientCommands.LIST_CLEAR_SELECTED_ITEMS:
        case ClientCommands.DATALIST_CLEAR_SELECTED_ITEMS:
          component[controlName]?.deselectAll();
          break;
        case ClientCommands.EXECUTE_JS:
          eval(params[0]);
          break;
        case ClientCommands.GRID_GOTO_FIRST_PAGE:
          component[controlName]?.goToFirst();
          break;
        case ClientCommands.GRID_GOTO_NEXT_PAGE:
          component[controlName]?.next();
          break;
        case ClientCommands.GRID_GOTO_PREV_PAGE:
          component[controlName]?.prev();
          break;
        case ClientCommands.GRID_GOTO_LAST_PAGE:
          component[controlName]?.goToLast();
          break;
        case ClientCommands.GRID_GOTO_PAGE:
          const page = params[1];
          component[controlName]?.goToPage(page);
          break;
        default:
          console.error(`Client command '${ClientCommands[command]}' not yet implemented!`);
      }
    } catch (e) {
      console.error(`Client command failed`);
      console.log(e);
    }
  }

  public handleShowMessage(params: ShowMessageParams) {
    let icon = "";
    let className = "";
    let buttonClassName = "";
    const messageType = params.Type;

    if (messageType === 'Warning' || messageType === Joove.MessageType.Warning) {
      icon = "pi pi-exclamation-triangle";
      className = "p-confirm-popup-warning";
      buttonClassName = "button-status-warning";
    } else if (messageType === 'Error' || messageType === Joove.MessageType.Error) {
      icon = "pi pi-times-circle";
      className = "p-confirm-popup-error";
      buttonClassName = "button-status-danger";
    } else if (messageType === 'Info' || messageType === Joove.MessageType.Info) {
      icon = "pi pi-info-circle";
      className = "p-confirm-popup-info";
      buttonClassName = "button-status-info";
    } else if (messageType === 'Success' || messageType === Joove.MessageType.Success) {
      icon = "pi pi-check";
      className = "p-confirm-popup-success";
      buttonClassName = "button-status-success";
    } else {
      icon = "pi pi-check";
      className = "p-confirm-popup-success";
      buttonClassName = "button-status-success";
    }

    this.confirmOptions$.next({ className });

    let message = params.Message;
    if (message != null && (typeof message) !== "string") {
      message = JSON.stringify(Joove.Cycles.decycleObject(message), null, 2);
    }

    this.confirmationService?.confirm({
      message: message,
      header: params.Title,
      icon: icon,
      rejectVisible: false,
      acceptButtonStyleClass: 'btn btn-default button-size-m ' + buttonClassName,
      acceptIcon: 'p-d-none', // hide the icon
      acceptLabel: 'OK',
      accept: () => {
        if (params.RedirectUrl == null) {
          return;
        }

        let url = params.RedirectUrl;

        if (environment.baseUrl == "" && url.indexOf("http") == 0) {
          window.location.href = url;
        } else if (url.indexOf(environment.baseUrl) >= 0) {
          url = params.RedirectUrl.replace(/([^:]\/)\/+/g, "$1");
          const baseUrlIndex = url.indexOf(environment.baseUrl);
          url = url.substr(baseUrlIndex);
          url = url.replace(environment.baseUrl, '');
          this.router.navigate([{ outlets: { modal: null } }])
            .then(() => this.router.navigate([url]));
        } else {
          window.location.href = url;
        }

      }
    });
  }

  public openFormInModal(url: string, formRenderPosition: string, size: number) {
    const renderPosition: Joove.FormRenderPosition = Joove.FormRenderPosition[formRenderPosition as keyof typeof Joove.FormRenderPosition];
    let isModal = false;
    let isSlide = false;
    let newTab = false;
    let sidebarPosition = 'right';
    let sizeDimension = 'width';

    switch (renderPosition) {
      case Joove.FormRenderPosition.NEW_TAB:
        newTab = true;
        break;
      case Joove.FormRenderPosition.MODAL:
        isModal = true;
        break;
      case Joove.FormRenderPosition.TOP_SLIDE:
        isSlide = true;
        sidebarPosition = 'top';
        sizeDimension = 'height';
        break;
      case Joove.FormRenderPosition.BOTTOM_SLIDE:
        isSlide = true;
        sidebarPosition = 'bottom';
        sizeDimension = 'height';
        break;
      case Joove.FormRenderPosition.LEFT_SLIDE:
        isSlide = true;
        sidebarPosition = 'left';

        break;
      case Joove.FormRenderPosition.RIGHT_SLIDE:
        isSlide = true;
        sidebarPosition = 'right';
        break;
    }

    if (newTab) {
      window.open(environment.baseUrl + `/${url}`);
    }

    const navigationExtras: NavigationExtras = {
      state: {
        style: `${sizeDimension}: ${size}%`, data: { isModal, isSlide, sidebarPosition }
      }
    };

    this.router.navigate([{ outlets: { modal: url } }], navigationExtras);
  }

  private setAuthToken(params) {
    this.authService.setSession({ expiresIn: params.ExpiresIn, idToken: params.Token });
  }

  private unsetAuthToken() {
    this.authService.logout();
  }

  private closeForm() {

    this.routerHistoryService.previousUrl$.pipe(take(1)).subscribe(({ url, history }: { url: string, history: HistoryEntry[] }) => {

      if (url == null) {
        if (this.router.url.split('/')[1] == environment.baseUrl) {
          window.location.reload();
        } else {
          this.router.navigate([`/`]);
        }
      } else {
        if (url.indexOf("(modal:") > -1) {
          let backItems = 1;
          for (let i = history.length - 2; i > 0; i--) {
            if (history[i].url.indexOf("(modal:") > -1) {
              backItems += 2;
              i--;
              continue;
            }
            break;
          }

          this.location.historyGo(-backItems);
        } else {
          // this.location.back();
          this.router.navigate([{ outlets: { modal: null } }])
            .then(() => this.router.navigate([url]));
        }
      }
      this.pubSubService.publish('form-modal-close', 'CLOSE_FORM');
    });
  }

  private downloadFile(url: string) {
    var link = document.createElement("a");
    link.setAttribute('download', '');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}

export interface ShowMessageParams {
  Message: string;
  Title: string;
  Type: string | Joove.MessageType;
  RedirectUrl?: string | undefined;
}

export interface ClientCommand {
  Command: string;
  Params: any[];
}

export enum ClientCommands {
  LIST_REFRESH = 0,
  LIST_CLEAR_SELECTED_ITEMS = 1,
  DATALIST_REFRESH = 2,
  DATALIST_CLEAR_SELECTED_ITEMS = 3,
  DATALIST_UPDATE_SIZE = 4,
  GRID_GOTO_LAST_PAGE = 5,
  GRID_GOTO_PREV_PAGE = 6,
  GRID_GOTO_NEXT_PAGE = 7,
  GRID_GOTO_FIRST_PAGE = 8,
  GRID_GOTO_PAGE = 9,
  CLEAR_GLOBAL_RESOURCES_CACHE = 10,
  SHOW_MESSAGE = 11,
  HIDE_MODAL = 12,
  SHOW_MODAL = 13,
  CHART_REFRESH = 14,
  PUSH_TO_NAVIGATION_HISTORY = 15,
  CLOSE_FORM = 16,
  REDIRECT = 17,
  GRID_REFRESH = 18,
  MAP_REFRESH = 19,
  MAP_DIRECTIONS = 20,
  MAP_FITCONTENT = 21,
  EXPORT_FORM_TO_PDF = 22,
  EXPORT_CONTROL_TO_PDF = 23,
  EXECUTE_JS = 24,
  DROPDOWN_REFRESH = 25,
  CALENDAR_REFRESH = 26,
  SET_DIRTY = 27,
  DOWNLOAD = 28,
  ImageRefresh = 29,
  SET_AUTH_TOKEN = 30,
  UNSET_AUTH_TOKEN = 31,
  SHOW_CONTEXT_MENU = 32,
  HIDE_CONTEXT_MENU = 33,
  PIVOTTABLE_REFRESH = 34,
}
